import { envVars } from '@/config';
import { UIInspector, initI8n } from '@/lib';
import { store } from '@/store';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

type Props = {
  children: React.ReactNode;
};

export function Providers({ children }: Props) {
  const { i18n } = initI8n();
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <UIInspector enabled={envVars.screenDebugger} />
        {children}
        {/* {auth?.requireAuth ? <Protected auth={auth}>{children}</Protected> : children} */}
      </Provider>
    </I18nextProvider>
  );
}
