/**
 * export all store api's
 */

import { globalApi } from './global/api';
import { objectApi } from './object/api';
import { robotApi } from './robots/api';

export const API = {
  robotApi,
  globalApi,
  objectApi,
};

export type APIKeys = keyof typeof API;
export type APIType = (typeof API)[APIKeys];
