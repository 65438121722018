import { ROUTES } from '@/config';
import { ObjectCard, ObjectCardSkeleton } from '@/core/blocks';
import { Carousel, CarouselContent, CarouselDots, CarouselItem } from '@/core/ui';
import { API } from '@/store/api';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const ObjectList = () => {
  const { i18n } = useTranslation();
  const data = API.objectApi.useGetObjectsQuery(
    { locale: i18n.language },
    {
      selectFromResult: ({ data }) => ({
        objects: data?.objects,
      }),
      skip: !i18n.language,
    },
  );

  // if (error) {
  //   return <p>Oh no, there was an error</p>;
  // }

  if (!data?.objects) {
    return (
      <div className='flex flex-row space-x-[50px] pt-[50px] pb-[70px] justify-center items-center'>
        {Array.from({ length: 4 }).map((_, index) => (
          <ObjectCardSkeleton key={index} />
        ))}
      </div>
    );
  }

  if (data.objects.length < 5) {
    return (
      <div className='flex w-full space-x-[50px] justify-center items-center pt-[50px] pb-[70px]'>
        {data.objects.map((item) => (
          <Link
            key={item.id}
            to={`${ROUTES.OBJECT.main}/${item.id}`}
            className='flex justify-center items-center'
          >
            <ObjectCard name={item.name} url={item.image.path} />
          </Link>
        ))}
      </div>
    );
  }

  return (
    <div className='flex w-full justify-center items-center'>
      <Carousel
        opts={{
          align: 'center',
          startIndex: 1,
          // dragThreshold: 2000,
          dragFree: true,
        }}
        className='w-full'
      >
        <CarouselContent wrapperClass='px-20 py-12'>
          {data.objects.map((item) => (
            <CarouselItem key={item.id} className='basis-[25%]'>
              <Link to={`${ROUTES.OBJECT.main}/${item.id}`} className='flex justify-center items-center'>
                <ObjectCard name={item.name} url={item.image.path} />
              </Link>
            </CarouselItem>
          ))}
        </CarouselContent>

        <CarouselDots className='' />
      </Carousel>
    </div>
  );
};
